import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home/home.vue'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Home
  // },
  {
    path: '/dataImport',
    name: 'DataImport',
    component: () => import('../views/dataImport/dataImport.vue')
  },
  // {
  //   path: '/order',
  //   name: 'OrderVolume',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/orderVolume/orderVolume.vue')
  // },
  {
    path: '/siteSales',
    name: 'SiteSales',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "SiteSales" */ '../views/siteSales/siteSales.vue')
  },
  // {
  //   path: '/manufacturer',
  //   name: 'Manufacturer',
  //   component: () => import('../views/manufacturer/manufacturer.vue')
  // },
  {
    path: '/',
    name: 'CommodityFlow',
    component: () => import(/* webpackChunkName: "CommodityFlow" */ '../views/commodityFlow/commodityFlow.vue')
  },
  // {
  //   path: '/serviceStation',
  //   name: 'ServiceStation',
  //   component: () => import('../views/serviceStation/serviceStation.vue')
  // }
  {
    path: '/logisticsAccount',
    name: 'LogisticsAccount',
    component: () => import(/* webpackChunkName: "LogisticsAccount" */ '../views/logisticsAccount/logisticsAccount.vue')
  },
  {
    path: '/viewData',
    name: 'viewData',
    component: () => import(/* webpackChunkName: "viewData" */ '../views/viewData/index.vue')
  },
  {
    path: '/meteorology',
    name: 'meteorology',
    component: () => import(/* webpackChunkName: "meteorology" */ '../views/meteorology/index.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
