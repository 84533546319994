<template>
  <div id="topPart" class="">
    <img class="topbar_center" :src="require('@/assets/topbar_center.png')" alt="" />
    <img class="topbar" :src="require('@/assets/topbar.png')" alt="" />
    <img class="topbar_right" :src="require('@/assets/topbar_right.png')" alt="" />
    <div class="title">崇信县电商(溯源)大数据中心</div>
    <div class="menu">
      <el-menu
        :default-active="route"
        background-color="rgba(0,0,0,0)"
        text-color="#ffffff"
        active-text-color="#00EAFF"
        mode="horizontal"
        :router="true"
      >
        <!-- <el-menu-item index="/" class="flex">
          <i class="el-icon-s-home shadow" />
          <span class="shadow">首页</span>
        </el-menu-item> -->
        <!-- <el-menu-item index="/dataImport" class="flex">
          <i class="el-icon-s-home shadow" />
          <span class="shadow">数据导入</span>
        </el-menu-item> -->

        <!-- <el-menu-item index="/manufacturer"><i class="el-icon-s-marketing" />生产企业</el-menu-item> -->
        <el-menu-item index="/"><i class="el-icon-s-data" />物流流向</el-menu-item>
        <el-menu-item index="/siteSales"> <i class="el-icon-s-order" />站点销量</el-menu-item>
        <el-menu-item index="/logisticsAccount"><i class="el-icon-s-shop" />物流台账</el-menu-item>
        <el-menu-item index="/viewData"><i class="el-icon-s-marketing" />溯源概览</el-menu-item>
        <el-menu-item index="/meteorology"><i class="el-icon-sunny" />气象分析</el-menu-item>
      </el-menu>
    </div>
    <div class="time">
      <div>{{ y }}</div>
      <div>{{ w }}</div>
      <div>{{ t }}</div>
      <div v-if="$route.path == '/viewData'" class="export-btn" @click="exportData">导出分析报告</div>
    </div>
  </div>
</template>
<script>
import { download } from '@/utils/index'
export default {
  name: 'TopPart',
  data() {
    return { date: new Date(), y: '', t: '', w: '' }
  },
  computed: {
    route() {
      return this.$store.state.route
    }
  },
  mounted() {
    const _this = this
    this.timer = setInterval(function () {
      _this.date = _this.dateFormat()
    })
  },
  beforeDestroy: function () {
    if (this.timer) {
      clearInterval(this.timer)
    }
  },
  methods: {
    // 时间格式化
    dateFormat() {
      var date = new Date()
      var year = date.getFullYear()
      //  在日期格式中，月份是从0开始的
      //  使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
      var month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
      var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
      var hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
      var minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
      var seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
      const week = date.getDay() // 星期
      const weekArr = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']
      // 拼接 时间格式处理
      this.y = year + '年' + month + '月' + day + '日'
      this.t = hours + ':' + minutes + ':' + seconds
      this.w = weekArr[week]
      return hours + ':' + minutes + ':' + seconds + ' ' + year + '年' + month + '月' + day + '日 ' + weekArr[week]
    },
    // 导出溯源概览的数据
    exportData() {
      download('/excel/import/exportDate', {}, '大数据分析报告.xlsx')
    }
  }
}
</script>

<style lang="scss">
@import './index';
</style>
