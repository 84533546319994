<template>
  <div :id="id" :style="{ width: width, height: height }" />
</template>

<script>
import * as echarts from 'echarts'

export default {
  name: 'ECharts',
  props: {
    id: {
      type: String,
      require: true,
      default: ''
    },
    className: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      default: ''
    },
    options: {
      type: Object,
      default: () => ({})
    },
    /* upDate用于判断是否需要更新全部图形,
            true  为更新全部图形（重新加载图形）,
           	false 为追加数据，只更新部分数据图形
            */
    upDate: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      myChart: null
    }
  },
  watch: {
    options: {
      deep: true,
      handler() {
        if (this.upDate) {
          this.initCharts()
        }
      }
    }
  },
  mounted() {
    this.initCharts()
  },
  methods: {
    initCharts() {
      const _this = this
      this.myChart = echarts.init(document.getElementById(this.id))
      this.myChart.clear() // 清除echarts,防止图形变化重复渲染
      this.myChart.setOption(this.options)
      this.myChart.off() // 防止多次点击图形
      // window.onresize = this.myChart.resize // 自适应浏览器窗口大小
      window.addEventListener('resize', () => {
        this.myChart.resize
      })
      this.myChart.on('click', function (params) {
        _this.$parent.clickEcharts(params, _this.id)
      }) // 点击事件
      this.myChart.on('datazoom', function (params) {
        _this.$parent.dataZoomEcharts(params, _this.id)
      }) // 缩放或滑道事件
    }
  }
}
</script>
