<template>
  <div id="app"><img class="total_bg" :src="require('@/assets/bg.png')" alt="" /><topPart /><router-view /></div>
</template>

<script>
import topPart from '@/components/topPart'
export default {
  name: '',
  components: { topPart },

  data() {
    return {}
  },
  mounted() {
    console.log(process.env.VUE_APP_CONNECT_API)
  }
}
</script>

<style lang="scss">
body {
  margin: 0;
  padding: 0;
}
#app {
  font-family: PingFangSC-Regular, PingFang SC, Avenir, Helvetica, Arial, sans-serif;
  height: 100vh;
  .total_bg {
    position: fixed;
    z-index: -1;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
  }
}
.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex-jc-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.flex_column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.part {
  position: relative;
  overflow: hidden;
  .part_con {
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
    z-index: 1;
    color: #fff;
    padding-top: 42px;
  }
  .part_con_noTitle {
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
    z-index: 1;
    color: #fff;
  }
}
.w100 {
  width: 100%;
}
.w50 {
  width: 50%;
}
.w25 {
  width: 25%;
}
// .w38_v {
//   width: 38vw;
// }
// .w29_v {
//   width: 29vw;
// }
.pd1h {
  padding: 1vh;
}
.mt2h {
  margin-top: 2vh;
}
.mt1h{
  margin-top: 1vh;
}
.mb1h{
  margin-bottom: 1vh;
}
.pt20px{
  padding-top: 20px;
}
</style>
